













































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import { addSchool, querySchool } from "@/api/school";
import { getAreaCascadeList } from "@/api/school";
import { School } from "@/tool/interface-index";
import { uuid } from "@/tool";
import * as _ from "lodash";
@Component({
  name: "CreateSchool",
  components: {
    cardBox,
  },
})
export default class extends Vue {
  private cascadeAreaId: string[] = [];
  private ruleForm: School = {
    schoolId: uuid(),
    schoolName: "",
    primarySchool: false,
    middleSchool: true,
    highSchool: false,
    address: "",
    areaId: "",
    areaName: "",
    contactPerson: "无",
    contactPersonPhone: "无",
    contactPersonEmail: "无",
    contactPerson02: "",
    contactPersonPhone02: "",
    contactPersonEmail02: "",
    registrationCode: "",
    classRoomLimitNumber: 0,
    examRoomLimitNumber: 0,
    teacherAccountLimitNumber: 0,
  };
  rules = {
    areaId: [{ required: true, message: "请选择地区", trigger: "change" }],
    schoolName: [
      { required: true, message: "请输入学校名称", trigger: "change" },
    ],
    address: [{ required: true, message: "请输入学校地址", trigger: "change" }],
    // areaId: [{ required: true, message: "请输入学校区域", trigger: "change" }],
    contactPerson: [
      { required: true, message: "请输入联系人名称", trigger: "change" },
    ],
    contactPersonEmail: [
      { required: true, message: "请输入联系人邮箱", trigger: "change" },
    ],
    contactPersonPhone: [
      { required: true, message: "请输入联系人电话", trigger: "change" },
    ],
  };

  private areas: any[] = [];
  private pageType: string = "";
  private title: string = "";
  private get type(): string {
    if (this.pageType == "add") {
      this.title = "创建学校";
      return "立即创建";
    }
    if (this.pageType == "edit") {
      this.title = "编辑学校";
      return "保存";
    }
    return "立即创建";
  }
  submitForm() {
    (this as any).$refs.ruleForm.validate((valid: any) => {
      if (valid) {
        addSchool(this.ruleForm).then((res: any) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "创建成功!",
            });
          } else {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
        });
      } else {
        console.log("error submit!!");
        return false;
      }
    });
  }
  doGoBackClick() {
    this.$router.go(-1);
  }
  resetForm(formName: string | number) {
    (this as any).$refs.ruleForm.resetFields();
  }

  private getCascadeItem(keyId: string, cascades: any[]): any {
    let result = undefined;
    cascades.some(function iter(obj) {
      if (obj.keyId == keyId) {
        result = obj;
        return true;
      }
      return Array.isArray(obj.children) && obj.children.some(iter);
    });
    return result;
    /*
    for (let i = 0; i < cascades.length; i++) {
      if (cascades[i].keyId == keyId) {
        return cascades[i]
      }
      if (_.has(cascades[i], 'children')) {
        return this.getCascadeItem(keyId, cascades[i].children);
      }

    }
    */
  }

  private getCascadeArealId(areaId: string): string[] {
    let arr: string[] = [];
    let keyId: string = areaId;
    do {
      const item = this.getCascadeItem(keyId, this.areas);
      if (item) {
        arr.push(keyId);
        keyId = item.parentKeyId;

        continue;
      }
      break;
    } while (true);
    return arr.reverse();
  }

  private removeNullChildren(cascades: any[]) {
    let i: number = 0;
    while (i < cascades.length) {
      switch (_.get(cascades[i], "dataType", "")) {
        case "area": {
          if (_.get(cascades[i], "children", []).length == 0) {
            delete cascades[i]["children"];
          } else {
            this.removeNullChildren(cascades[i].children);
          }
          i++;
          break;
        }
        default:
          i++;
      } //switch
    }
  }

  handleAreaIdChange(value: string[]) {
    this.ruleForm.areaId = _.last(value) || "";
  }

  private async __init() {
    const { data } = await getAreaCascadeList();
    this.areas = data.items;
    this.removeNullChildren(this.areas);

    if (this.$route.query.schoolId) {
      const { data } = await querySchool(this.$route.query.schoolId as string);
      this.ruleForm = data;
      this.cascadeAreaId = this.getCascadeArealId(this.ruleForm.areaId);
    }
    this.pageType = this.$route.query.type as string;
  }

  mounted() {
    this.__init();
  }
}
